import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Attachment, UUID } from '@/types/types';
import { StoreKeys } from '@/configs/StoreKeys';
import { FormTemplate, QuestionType } from '@/api/FormTemplateApi';
import APP_CONFIG from '@/configs/appConfig';

export interface AnswerState {
	isNew: boolean;
	id: UUID;
	name: string;
	description?: string;
	image?: Attachment;
	listOrder: number;
}

export interface QuestionState {
	isNew: boolean;
	id: UUID;
	name: string;
	description?: string;
	suffix: string;
	questionType: QuestionType;
	listOrder: number;
	answers: Record<UUID, AnswerState>;
}

export interface QuestionGroupState {
	isNew: boolean;
	id: UUID;
	name: string;
	description: string;
	image?: Attachment;
	listOrder: number;
	questions: Record<UUID, QuestionState>;
}

export type EditFormTemplateRemovedEntities = {
	questionGroups: UUID[];
	questions: UUID[];
	answers: UUID[];
};

export interface FormTemplateState {
	id: UUID;
	productType: null | UUID;
	questionGroups: Record<UUID, QuestionGroupState>;
	removedEntities: EditFormTemplateRemovedEntities;
}

const initialState: FormTemplateState = {
	id: crypto.randomUUID(),
	productType: null,
	questionGroups: {},
	removedEntities: {
		questionGroups: [],
		questions: [],
		answers: [],
	},
};

export const formTemplateSlice = createSlice({
	name: StoreKeys.formTemplate,
	initialState,
	reducers: {
		resetState: (state) => {
			state.productType = null;
			state.removedEntities = {
				questionGroups: [],
				questions: [],
				answers: [],
			};

			const questionGroup = getBlankQuestionGroup();

			state.questionGroups = {
				[questionGroup.id]: questionGroup,
			};
		},
		loadTemplate: (state, { payload }: PayloadAction<FormTemplate>) => {
			state.id = payload.id;
			state.productType = payload.productType.id;

			state.questionGroups = payload.questionGroups.reduce<
				FormTemplateState['questionGroups']
			>((questionGroupsAcc, questionGroup) => {
				questionGroupsAcc[questionGroup.id] = {
					isNew: false,
					id: questionGroup.id,
					name: questionGroup.name,
					description: questionGroup.description,
					image: questionGroup.image,
					listOrder: questionGroup.listOrder,
					questions: questionGroup.questions.reduce<
						QuestionGroupState['questions']
					>((questionsAcc, question) => {
						questionsAcc[question.id] = {
							isNew: false,
							id: question.id,
							name: question.name,
							description: question.description,
							suffix: question.suffix,
							questionType: question.questionType,
							listOrder: question.listOrder,
							answers: question.answers.reduce<QuestionState['answers']>(
								(answersAcc, answer) => {
									answersAcc[answer.id] = {
										isNew: false,
										id: answer.id,
										name: answer.name,
										description: answer.description,
										image: answer.image,
										listOrder: answer.listOrder,
									};

									return answersAcc;
								},
								{}
							),
						};

						return questionsAcc;
					}, {}),
				};

				return questionGroupsAcc;
			}, {});
		},
		handleChange: (
			state,
			{ payload }: PayloadAction<Partial<FormTemplateState>>
		) => {
			Object.assign(state, payload);
		},
		handleAddQuestionGroup: (state) => {
			const questionGroup = getBlankQuestionGroup();

			const highestListOrder = Math.max(
				0,
				...Object.values(state.questionGroups).map(
					(questionGroup) => questionGroup.listOrder
				)
			);
			questionGroup.listOrder = highestListOrder + 1;

			state.questionGroups[questionGroup.id] = questionGroup;
		},
		handleQuestionGroupDelete: (state, { payload }: PayloadAction<UUID>) => {
			if (!state.questionGroups[payload].isNew)
				state.removedEntities.questionGroups.push(payload);

			delete state.questionGroups[payload];
		},
		handleQuestionGroupChange: (
			state,
			{
				payload,
			}: PayloadAction<{ id: UUID; data: Partial<QuestionGroupState> }>
		) => {
			Object.assign(state.questionGroups[payload.id], payload.data);
		},
		handleQuestionChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				data: Partial<QuestionState>;
			}>
		) => {
			const question =
				state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				];

			Object.assign(question, payload.data);

			if (
				payload.data.questionType &&
				APP_CONFIG.NON_ANSWER_QUESTION_TYPES.includes(payload.data.questionType)
			)
				question.answers = {};
		},
		handleAddQuestion: (state, { payload }: PayloadAction<UUID>) => {
			const question = getBlankQuestion();

			const highestListOrder = Math.max(
				0,
				...Object.values(state.questionGroups[payload].questions).map(
					(question) => question.listOrder
				)
			);
			question.listOrder = highestListOrder + 1;

			Object.assign(state.questionGroups[payload].questions, {
				[question.id]: question,
			});
		},
		handleQuestionDelete: (
			state,
			{ payload }: PayloadAction<{ questionGroupId: UUID; questionId: UUID }>
		) => {
			if (
				!state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].isNew
			)
				state.removedEntities.questions.push(payload.questionId);

			delete state.questionGroups[payload.questionGroupId].questions[
				payload.questionId
			];
		},
		handleAddAnswer: (
			state,
			{ payload }: PayloadAction<{ questionGroupId: UUID; questionId: UUID }>
		) => {
			const answer = getBlankAnswer();

			const highestListOrder = Math.max(
				0,
				...Object.values(
					state.questionGroups[payload.questionGroupId].questions[
						payload.questionId
					].answers
				).map((answer) => answer.listOrder)
			);
			answer.listOrder = highestListOrder + 1;

			Object.assign(
				state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].answers,
				{
					[answer.id]: answer,
				}
			);
		},
		handleAnswerDelete: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				answerId: UUID;
			}>
		) => {
			if (
				!state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].answers[payload.answerId].isNew
			)
				state.removedEntities.answers.push(payload.answerId);

			delete state.questionGroups[payload.questionGroupId].questions[
				payload.questionId
			].answers[payload.answerId];
		},
		handleAnswerChange: (
			state,
			{
				payload,
			}: PayloadAction<{
				questionGroupId: UUID;
				questionId: UUID;
				answerId: UUID;
				data: Partial<AnswerState>;
			}>
		) => {
			Object.assign(
				state.questionGroups[payload.questionGroupId].questions[
					payload.questionId
				].answers[payload.answerId],
				payload.data
			);
		},
	},
});

export const formActions = formTemplateSlice.actions;

export default formTemplateSlice.reducer;

function getBlankQuestionGroup(): QuestionGroupState {
	const question = getBlankQuestion();

	return {
		isNew: true,
		id: crypto.randomUUID(),
		name: '',
		description: '',
		listOrder: 1,
		questions: {
			[question.id]: question,
		},
	};
}

function getBlankQuestion(): QuestionState {
	const answer = getBlankAnswer();

	return {
		isNew: true,
		id: crypto.randomUUID(),
		name: '',
		description: '',
		suffix: '',
		questionType: QuestionType.SELECT,
		listOrder: 1,
		answers: {
			[answer.id]: answer,
		},
	};
}

function getBlankAnswer(): AnswerState {
	return {
		isNew: true,
		id: crypto.randomUUID(),
		name: '',
		description: '',
		listOrder: 1,
	};
}
