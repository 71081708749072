export const ApiQueryKey = {
	products: 'products',
	productTypes: 'product-types',
	componentCategories: 'component-categories',
	components: 'components',
	attributes: 'attributes',
	attributeValues: 'attribute-values',

	formTemplates: 'form-templates',
	forms: 'forms',

	admins: 'admins',
	roles: 'roles',

	globalSettings: 'global-settings',
} as const;

export type ApiQueryKey = (typeof ApiQueryKey)[keyof typeof ApiQueryKey];
