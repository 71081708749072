import React, { CSSProperties, forwardRef } from 'react';
import { Box, MantineLoaderComponent } from '@mantine/core';
import classnames from 'classnames';

export const SpinnerLoader: MantineLoaderComponent = forwardRef(
	({ className, ...others }, ref) => (
		<Box
			className={classnames('spinner-chase', className)}
			{...others}
			ref={ref}
		>
			<div className="chase-dot" />
			<div className="chase-dot" />
			<div className="chase-dot" />
			<div className="chase-dot" />
			<div className="chase-dot" />
			<div className="chase-dot" />
		</Box>
	)
);

interface Props {
	height?: CSSProperties['height'];
}

export const Loading: React.FC<Props> = ({ height }) => {
	return (
		<Box id="preloader" style={height ? { height } : {}}>
			<div id="status">
				<SpinnerLoader />
			</div>
		</Box>
	);
};
