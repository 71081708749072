import { HttpStatusCode } from 'axios';

export interface ApiError {
	error: string;
	message: ErrorCode | ErrorCode[] | string | string[];
	status: HttpStatusCode;
}

export interface AppError {
	status: HttpStatusCode;
	stack: (ErrorCode | string)[];
	message: string;
}

export enum ErrorCode {
	GENERIC = 'genericError',
	INVALID_FILE_TYPE = 'cantUploadFileType',
	USER_NOT_FOUND = 'userNotFound',
	USER_NOT_ACTIVE = 'userNotActive',
	INVALID_EMAIL = 'emailIsNotValid',
	DUPLICATED_EMAIL = 'duplicatedEmail',
	MISSING_FIELD = 'missingField',
	PASSWORD_TO_WEAK = 'passwordToWeak',
	INVALID_PASSWORD = 'invalidPassword',
	INVALID_REFRESH_TOKEN = 'invalidRefreshToken',
	REFRESH_TOKEN_EXPIRED = 'refreshTokenExpired',
	INVALID_TOKEN = 'invalidToken',
	INVALID_HASH = 'invalidHash',
	INVALID_POSTAL_CODE = 'invalidPostalCode',
	PRODUCT_CANNOT_BE_UPDATED = 'productCannotBeUpdated',
	DUPLICATED_NAME = 'duplicatedName',
	TOO_MANY_REQUESTS = 'tooManyRequests',
}

export const getErrorMessage = (codes: ErrorCode[] | string[]) => {
	const codeToMessage = (code: ErrorCode | string) => {
		switch (code) {
			case ErrorCode.PASSWORD_TO_WEAK:
				return 'Hasło nie spełnia wymogów bezpieczeństwa!';
			case ErrorCode.INVALID_FILE_TYPE:
				return 'Format pliku nieobsługiwany!';
			case ErrorCode.USER_NOT_FOUND:
				return 'Taki email nie istnieje w systemie.';
			case ErrorCode.INVALID_PASSWORD:
				return 'Podane hasło jest nieprawidłowe!';
			case ErrorCode.DUPLICATED_EMAIL:
				return 'Podany adres email jest już zajęty!';
			case ErrorCode.INVALID_REFRESH_TOKEN:
				return 'Nieprawidłowy token!';
			case ErrorCode.REFRESH_TOKEN_EXPIRED:
				return 'Token wygasł!';
			case ErrorCode.INVALID_TOKEN:
				return 'Podano nieprawidłowy token!';
			case ErrorCode.INVALID_HASH:
				return 'Podano nieprawidłowy token!';
			case ErrorCode.MISSING_FIELD:
				return 'Nie podano jednego z wymaganych pól!';
			case ErrorCode.DUPLICATED_NAME:
				return 'Podana nazwa jest zajęta!';
			default:
				return '';
		}
	};

	const errorMessages = codes.map((code) => codeToMessage(code));

	const message =
		errorMessages.filter((m) => !!m).join('. ') ||
		'Coś poszło nie tak! Spróbuj ponownie później.';

	return message;
};
