export enum AppPaths {
	LOGIN = 'login',
	RESET_PASSWORD = 'reset-password',
	SET_PASSWORD = 'set-password',

	FORM_TEMPLATES = 'form-templates',
	FORMS = 'forms',

	PRODUCT_TYPES = 'product-types',

	ADMINS = 'admins',
	ROLES = 'roles',

	GLOBAL_SETTINGS = 'global-settings',
}
